import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import styles from './DownloadPdfButton.module.scss'

const DownloadPDFButton = ({ materials }) => {
  const handleDownload = () => {
    const doc = new jsPDF();

    doc.text("Materiales de la Cotización", 14, 10);
    const tableBody = materials.map(material => [
      material.name,
      material.category,
      material.unit,
      material.quantity,
      material.price,
    ]);
    const total = materials.reduce((sum, material) => sum + material.price * material.quantity, 0);
    tableBody.push(['', '', 'Total', total.toFixed(2)]);

    doc.autoTable({
      head: [['Material', 'Categoría', 'unidad', 'Cantidad', 'Precio']],
      body: tableBody,
      startY: 20, 
    });

    doc.save('materiales_cotizacion.pdf');
  };

  return (
    <button className={styles['download-btn']} onClick={handleDownload} disabled={materials.length === 0}>
      Descargar
    </button>
  );
};

export default DownloadPDFButton;
