import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import AddCustomMaterialPopup from './AddCustomMaterialPopup';
import SendQuotePopup from './SendQuotePopup';
import styles from './CalculadoraDeMateriales.module.scss';
import DownloadPDFButton from './DownloadPdfButton';
import { getCategoriesWithMaterials } from '../../../store/actions/categoryActions';

const MaterialSelectionTool = ({ allcategories, getCategoriesWithMaterials }) => {
  const [categories, setCategories] = useState(allcategories);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState(['electrical', 'plumbing']);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showAddCustomPopup, setShowAddCustomPopup] = useState(false);
  const [showSendQuotePopup, setShowSendQuotePopup] = useState(false);

  useEffect(() => {
    getCategoriesWithMaterials();
  }, [getCategoriesWithMaterials]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredMaterials([]);
      return;
    }

    const filtered = (categories.data || []).flatMap((category) => {
      return (category.materials || [])
        .filter((material) => {
          return material.name.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .map((material) => ({
          ...material,
          category: category.name,
        }));
    });

    setFilteredMaterials(filtered);
  }, [searchTerm, categories]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };
  const toggleMaterial = (material, categoryId) => {
    const category = categories?.data?.find((cat) => cat.id === categoryId)?.name || categoryId;

    setSelectedMaterials((prev) => {
      const existingIndex = prev.findIndex((m) => m.id === material.id);
      if (existingIndex >= 0) {
        return prev.filter((m) => m.id !== material.id);
      } else {
        return [...prev, { ...material, quantity: 1, category }];
      }
    });
  };

  const updateQuantity = (id, quantity) => {
    setSelectedMaterials((prev) =>
      prev.map((m) => (m.id === id ? { ...m, quantity } : m))
    );
  };

  const removeMaterial = (id) => {
    setSelectedMaterials((prev) => prev.filter((m) => m.id !== id));
  };

  const addCustomMaterial = (newMaterial) => {
    if (!Array.isArray(categories.data)) {
      console.error("Categories data is not an array");
      return;
    }

    const categoryIndex = categories.data.findIndex((c) => c.id === newMaterial.categoryId);

    if (categoryIndex !== -1) {
      setCategories((prevCategories) => {
        const newCategories = { ...prevCategories };
        newCategories.data = [...prevCategories.data];

        // newCategories.data[categoryIndex] = {
        //   ...newCategories.data[categoryIndex],
        //   materials: [...newCategories.data[categoryIndex].materials, { ...newMaterial, temporary: true }],
        // };

        return newCategories;
      });
    } else {
      setCategories((prevCategories) => ({
        ...prevCategories,
        data: [
          ...prevCategories.data,
          { id: newMaterial.categoryId, name: newMaterial.category, materials: [{ ...newMaterial, temporary: true }], jurisdiction: newMaterial.jurisdiction, currency: newMaterial.currency },
        ],
      }));
    }

    setSelectedMaterials((prevSelected) => [
      ...prevSelected,
      {
        ...newMaterial,
        category: categories.data.find((c) => c.id === newMaterial.categoryId)?.name || newMaterial.category,
        unit: newMaterial.unit,
        quantity: newMaterial.quantity || 1,
        temporary: true,
        currency: newMaterial.currency,
      },
    ]);

    setShowAddCustomPopup(false);
  };

  const total = selectedMaterials.reduce((sum, m) => sum + m.price * m.quantity, 0);

  const groupedMaterials = selectedMaterials.reduce((acc, material) => {
    if (!acc[material.category]) {
      acc[material.category] = [];
    }
    acc[material.category].push(material);
    return acc;
  }, {});

  const handleSendQuoteClick = () => {
    setShowSendQuotePopup(true);
  };

  const handleQuoteSuccess = () => {
    setSelectedMaterials([]); // Limpiamos la lista de materiales
  };

  return (
    <div className={styles['material-selection-tool']}>
      <div className={styles.categories}>
        <h2>Categorías</h2>
        <div className={styles['search-container']}>
          <FontAwesomeIcon icon={faSearch} className={styles['search-icon']} />
          <input
            type="text"
            placeholder="Buscar material..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={styles['search-input']}
          />
        </div>

        {searchTerm && filteredMaterials.length > 0 && (
          <div className={styles['filtered-results']}>
            <ul>
              {filteredMaterials.map((material) => (
                <li key={material.id} onClick={() => toggleMaterial(material, material.category)}>
                  <span className={styles['material-name']}>{material.name}</span>
                  <span className={styles['material-category']}>{material.category.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {searchTerm && filteredMaterials.length === 0 && (
          <div className={styles['filtered-results']}>
            <p>No se encontraron materiales</p>
          </div>
        )}


        <div className={styles['category-list']}>
          {categories?.data?.map((category) => (
            <div key={category.id} className={styles.category}>
              <button
                className={styles['category-header']}
                onClick={() => toggleCategory(category.id)}
              >
                <FontAwesomeIcon
                  icon={expandedCategories.includes(category.id) ? faChevronDown : faChevronRight}
                  className={styles.icon}
                />
                {category.name}
              </button>
              {expandedCategories.includes(category.id) && (
                <ul className={styles['material-list']}>
                  {category?.materials?.map((material) => (
                    <li key={material.id}>
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedMaterials.some((m) => m.id === material.id)}
                          onChange={() => toggleMaterial(material, category.id)}
                        />
                        {material.name}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>

      </div>

      <div className={styles['selected-materials']}>
        <div className={styles['table-container']}>
          <table>
            <thead>
              <tr>
                <th>Material</th>
                <th>Unidad</th>
                <th>Cantidad</th>
                <th>Precio Unidad</th>
                <th>Subtotal</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedMaterials)?.map(([category, materials]) => (
                <React.Fragment key={category}>
                  <tr className={styles['category-row']}>
                    <td colSpan="6">{category}</td>
                  </tr>
                  {materials?.map((material) => (
                    <tr key={material.id}>
                      <td className={styles['material-name']}>{material.name}</td>
                      <td>{material.unit}</td>
                      <td>
                        <input
                          type="number"
                          min="1"
                          value={material.quantity}
                          onChange={(e) => updateQuantity(material.id, parseInt(e.target.value) || material.quantity)}
                        />
                      </td>
                      <td>{material.price}</td>
                      <td className={styles['material-subtotal']}>{material.price * material.quantity}</td>
                      <td>
                        <button onClick={() => removeMaterial(material.id)} className={styles['remove-btn']}>
                          <FontAwesomeIcon icon={faTrash} className={styles['trash-icon']} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4">TOTAL</td>
                <td>{total}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={styles['actions']}>
          <button className={styles['add-custom']} onClick={() => setShowAddCustomPopup(true)}>
            Añadir personalizado
          </button>
          <div className={styles['main-actions']}>
            <button className={styles['quote-btn']} onClick={handleSendQuoteClick} disabled={selectedMaterials.length === 0}>
              Enviar para Cotizar
            </button>
            <DownloadPDFButton materials={selectedMaterials} />
          </div>
        </div>
      </div>
      {showAddCustomPopup && (
        <AddCustomMaterialPopup
          onClose={() => setShowAddCustomPopup(false)}
          onAdd={addCustomMaterial}
          categories={categories}
        />
      )}
      {showSendQuotePopup && (
        <SendQuotePopup
          onClose={() => setShowSendQuotePopup(false)}
          onSend={handleSendQuoteClick}
          materials={selectedMaterials}
          onSuccess={handleQuoteSuccess}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  allcategories: state.category.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategoriesWithMaterials: () => dispatch(getCategoriesWithMaterials()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSelectionTool);
