import React from "react";
import styles from "./ButtonSeeProject.module.scss";

function ButtonSeeProject({
  link,
  onClick,
  rigthText,
  moreInfo,
}) {

  return (
    <div
      className={`${link === null ? styles.moreInfo : styles.dnone} ${
        moreInfo ? styles.open : ""
      }`}
      onClick={onClick}
    >
      <p className={styles.text}>{rigthText}</p>     
    </div>
  );
}

export default ButtonSeeProject;
