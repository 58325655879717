import React from "react";
import styles from "./AllPrivateProjects.module.scss";
import { connect } from "react-redux";
import AllProjectsItem from "../../AllProjectsItem/AllProjectsItem";


const AllPrivateProjects = ({
  currentPage,
  item,
  key,
  ...props
}) => {



  return (
    <div className={styles.AllPrivateProjects}>
      <AllProjectsItem
        key={key}
        item={item}
        currentPage={currentPage}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};

export default connect(mapStateToProps, {
})(AllPrivateProjects);
