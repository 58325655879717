import React, { useEffect, useState } from "react";
import styles from "./AllProjectsItem.module.scss";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearSubProjectBudgets,
} from "../../../store/actions/profileActions";
import defaultImage from "../../../assets/images/foto-bienvenida.jpg";
import ButtonSeeProject from "../../ButtonSeeProject/ButtonSeeProject";
import usePremiumCheck from "../../../hooks/usePremiumCheck";
import { Carousel } from "nuka-carousel";


const AllProjectsItem = ({
  clearSubProjectBudgets,
  item,
  ...props
}) => {

  const [moreInfo, setMoreInfo] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const navigate = useNavigate();
  const premiumCheck = usePremiumCheck();
  let {
    link,
    name,
    startDate,
    endDate,
    zone,
    user,
  } = item;

  // Lee el contenido de los archivos y genera las URLs de imagen
  useEffect(() => {
    if (item?.proyecto_images) {
      if (Array.isArray(item?.proyecto_images) && item.proyecto_images.length > 0) {
        const urls = item.proyecto_images.map((img) => img.image);
        setImageUrls(urls);
      } else if (item?.image) {
        setImageUrls([item.image]);
      }
    } else if (item?.image) {
      setImageUrls([item.image]);
    } else {
      setImageUrls([defaultImage]);
    }
  }, [item]);

  const handleOpenLightbox = (index) => {
    setCurrentImageIndex(index);
    setShowLightbox(true);
  };

  const handleCloseLightbox = () => {
    setShowLightbox(false);
  };

  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Formatear los campos
  const formattedName = capitalizeFirstLetter(name);
  const textButton = "Ver más";

  const handleRedirectToProject = () => {
    clearSubProjectBudgets();
    navigate(`/obrasprivadas/${item.id}`)
  };

  const handleRedirectToUser = () => {
    const userName = user?.type === "COMPANY" ? user?.razon_social : `${user?.name} ${user?.last_name}`;
    const userId = user?.id;
    navigate(`/profesionales/${userId}/${userName}`);
  };

  return (
    <div className={styles.AllProjectsItemContainer}>
      <ul className={styles.AllProjectsItemList}>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column1}`}>
          <Carousel
            showArrows={item?.proyecto_images.length > 1 ? true : false}
            wrapMode
            slidesToShow={1}
            className={styles.AllProjectsItemList_carousel}
          >
            {item?.proyecto_images && item?.proyecto_images?.length > 0 ? item?.proyecto_images.map((img, index) => (
              <div key={img.id} className={styles.customImg_container} onClick={() => handleOpenLightbox(index)}>
                <img src={img.image} alt="" className={styles.customImg} />
              </div>
            )) : (
              <div className={styles.customImg_container}>
                <img src={defaultImage} alt="" className={styles.customImg} />
              </div>
            )}
          </Carousel>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column2}`}>{formattedName}</li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column3}`}>
          <div className={styles.subprojectContainer_titles}>
            {Array.isArray(item?.subproyecto) &&
              item?.subproyecto.map((subproject, index) => {
                return (
                  <div className={styles.subprojectContainer_titles_item} key={index}>
                    <p className={styles.subprojectContainer_titles_item_p}>{subproject.name}</p>
                  </div>
                );
              })}
          </div>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column4}`}>
          <p className={styles.dateAndZone_dates_p}>
            {formatDate(startDate)}
          </p>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column5}`}>
          <p className={styles.dateAndZone_dates_p}>
            {formatDate(endDate)}
          </p>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column6}`}>{zone}</li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column7}`}>
          <div className={styles.flex_buttonSeeMore}>
            <ButtonSeeProject
              link={link}
              onClick={premiumCheck(handleRedirectToProject)}
              rigthText={textButton}
              moreInfo={moreInfo}
              key={item.id}
            />
          </div>
        </li>
        <li className={`${styles.AllProjectsItemList_listItem} ${styles.column8}`}>
          <div className={styles.AllProjectsItemList_listItem_userData} onClick={() => handleRedirectToUser()}>
            <img src={user?.image_profile} alt="Foto de perfil" className={styles.AllProjectsItemList_listItem_userData_img} />
            {user?.type === "COMPANY" ? user?.razon_social : `${user?.name} ${user?.last_name}`}
          </div>
        </li>
      </ul>
      {
        showLightbox && (
          <div className={styles.lightbox}>
            <div className={styles.lightbox_container}>
              <span className={styles.close_btn} onClick={handleCloseLightbox}>X</span>
              <img src={imageUrls[currentImageIndex]} alt="Full-size" />
            </div>
          </div>
        )
      }
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
  };
};

export default connect(mapStateToProps, {
  clearSubProjectBudgets,
})(AllProjectsItem);
