import React from "react";
import LicitacionesItem from "../components/Dashboard/LicitacionesPublicas/LicitacionesItem";
import AdjudicatedItem from "../components/Dashboard/PublicProjectsAdjudicated/AdjudicadosItem";
import QuotationsList from "../components/MarketPlace/QuotationsList";
import AllPrivateProjects from "../components/Dashboard/AllProjects/AllPrivateProjects/AllPrivateProjects";
import styles from "../hooks/pagination.module.scss";
import usePremiumCheck from "./usePremiumCheck";
import { connect } from "react-redux";
import { ReactComponent as ArrowRight } from "../assets/svg/arrowRightIcon.svg";
import { ReactComponent as LeftArrow } from "../assets/svg/arrowLeftIcon.svg";


const Pagination = ({ itemCount, take, isPublicProject, ...props }) => {

  const premiumCheck = usePremiumCheck();
  const { currentPage, maxPageLimit, minPageLimit, response } = props;
  const data = props.response.entities || props.response;
  const totalPage = isPublicProject ? Math.ceil(itemCount / take) : Math.ceil(response?.itemCount / response?.take);
  let pageIncrementEllipses = null;
  let pageDecremenEllipses = null;

  const pages = [];
  if (totalPage > 1) {
    for (let i = 1; i <= totalPage; i++) {
      pages.push(i);
    }
  }
  const handlePrevClick = () => {
    if (currentPage > 1) {
      props.onPrevClick();
    }
  };

  const handleNextClick = premiumCheck(() => {
    if (currentPage < totalPage) {
      props.onNextClick();
    }
  });

  const handlePageClick = premiumCheck((e) => {
    props.onPageChange(Number(e.target.id));
  });
  const pageNumbers = pages.map((page, index) => {
    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        <div
          key={page}
          id={page}
          onClick={handlePageClick}
          className={currentPage === page ? styles.active : null}
          style={{ cursor: "pointer" }}
        >
          {page + (index !== maxPageLimit - 1 && " - ")}
        </div>
      );
    } else {
      return null;
    }
  });

  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <div onClick={handleNextClick}>{"..."}</div>;
  }
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <div onClick={handlePrevClick}>{"..."}</div>;
  }
  
  const Component = (project, isQuotation) => {
    if (project.type === "PRIVATE" && !props.isQuotation) {
      return (
        <AllPrivateProjects item={project} key={project.id} />
      );
    } else if (props.isQuotation) {
      return <QuotationsList item={project} key={project.id} quotation />;
    } else if (project.type === "PUBLIC" && !isQuotation) {
      return <LicitacionesItem key={project.id} item={project} />;
    } else return <AdjudicatedItem key={project.id} item={project} />;
  };

  return (
    <div style={{ width: "100%" }}>
      {data && data.length > 0 && data?.map((project) => (      
          Component(project)
      ))}
      {totalPage > 1 && (
        <div className={styles.sectionDivider}>
          <div className={styles.numbers}>
            <div className={styles.arrowContainer}>
              <button
                onClick={handlePrevClick}
                disabled={currentPage === pages[0]}
                className={styles.arrow}
                title="Atrás"
                style={{ paddingRight: "20px", paddingLeft: "10px" }}
              >
                <LeftArrow width={20} height={20} />
              </button>
            </div>
            <div className={styles.numbersContainer}>
              {pageDecremenEllipses}
              {pageNumbers}
              {pageIncrementEllipses}
            </div>
            <div className={styles.arrowContainer}>
              <button
                onClick={handleNextClick}
                disabled={currentPage === pages[pages.length - 1]}
                className={styles.arrow}
                title="Siguiente"
                style={{ paddingLeft: "20px", paddingRight: "10px" }}
              >
                <ArrowRight width={20} height={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    take: state.dashboard.take,
    itemCount: state.dashboard.itemCount,
  }
}

export default connect(mapStateToProps)(Pagination);
