import React, { useState, useEffect } from "react";
import styles from "./UserInfo.module.scss";
import { connect } from "react-redux";
import { setFile, openMainModal, closeMainModal, testPersonaEdit,
  testEmpresaEdit, sendContactNotification } from "../../../store/actions/profileActions";
import  { openSendContactNotificationModal } from "../../../store/actions";
import { setPreviousLocation } from "../../../store/actions";
import { ReactComponent as WebIcon } from "../../../assets/svg/webIcon.svg";
import { ReactComponent as ContactMessageIcon } from "../../../assets/svg/sendPostComment.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/svg/arrowRightIcon.svg";
import { ReactComponent as StarFull } from "../../../assets/svg/starFullSvg.svg";
import { ReactComponent as HalfStar } from "../../../assets/svg/halfStarSvg.svg";
import { ReactComponent as StarEmpty } from "../../../assets/svg/emptyStarSvg.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Tooltip from "../ToolTip/ToolTip";



const UserInfo = ({
  userData,
  profileVisualizerData,
  myPerson,
  setIsEditing,
  openSendContactNotificationModal,
}) => {
  const {
    username,
    image_profile,
    type,
    razon_social,
    name,
    last_name,
    url_site_web
  } = myPerson ? userData : profileVisualizerData;

  const [loading, setLoading] = useState(true);
  const [showToolTip, setShowToolTip] = useState({
    email: {show: false, text: "Enviar correo"},
    web: {show: false, text: "Visitar sitio web"},
});

const handleTooltip = (tooltip) => {
  setShowToolTip((prevState) => ({
      ...prevState,
      [tooltip]: { ...prevState[tooltip], show: !prevState[tooltip].show },
  }));
};

  let userNameInfo;
  if (type === "COMPANY") {
    userNameInfo = razon_social;
  } else if (name && last_name) {
    userNameInfo = `${name} ${last_name}`;
  }

  const formattedUrl = url_site_web ? (url_site_web.startsWith('http') ? url_site_web : `https://${url_site_web}`) : '#';

  useEffect(() => {
    setLoading(false);
  }, [userNameInfo]);

  const userStars = myPerson ? userData?.stars?.toFixed(1 - (userData?.stars % 1 === 0)) : profileVisualizerData?.stars?.toFixed(1 - (profileVisualizerData?.stars % 1 === 0));

  let stars = [];
  const starsCount = userStars !== null ? Math.floor(userStars) : 0; 
  
  for (let i = 0; i < 5; i++) {
      if (i < starsCount) {
          stars.push(<StarFull key={i} width={15} height={15} />);
      } else if (i === starsCount && userStars % 1 !== 0) {
          stars.push(<HalfStar key={i} width={15} height={15} />);
      } else {
          stars.push(<StarEmpty key={i} width={15} height={15} />);
      }
  }

  const handleMail = (email) => {
    const isGmail = email?.includes("@gmail.com");
    const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${email}` : `mailto:${email}`;
    window.open(mailtoLink, "_blank");
}

  return (
    <div className={`${styles.userInfoContainer} `}>
      <div className={styles.userInfoContainer_imageAndName}>
        {image_profile &&
        <img  className={styles.userInfoContainer_imageAndName_img} src={image_profile} alt="Profile" />
        }    
        <div className={styles.userInfoContainer_data}>
          {userNameInfo && 
        <h2 className={styles.userInfoContainer_imageAndName_name}>{userNameInfo}</h2>
        }
          <div className={styles.userInfoContainer_stars}>
            {stars}
          </div>
        </div>
      </div>
      <div className={styles.userInfoContainer_buttonsContainer}>
        {myPerson && (
        <div className={styles.userInfoContainer_buttonsContainer_editProfile}  onClick={() => setIsEditing(true)}>
          <p>Editar perfil</p>
          <ArrowRightIcon width={10} height={10}/>
        </div>
        )}
        {!myPerson && ( 
          <>
          <div className={styles.userInfoContainer_buttonsContainer_webSite} 
            onClick={() => handleMail(username)}
            onMouseEnter={() => handleTooltip("email")}
            onMouseLeave={() => handleTooltip("email")}  >
          <FontAwesomeIcon icon={faEnvelope} style={{fontSize: "2rem"}} />
          {showToolTip.email.show && (
            <Tooltip text={showToolTip.email.text} fromHeaderNav />
          )}
          </div>
        <div className={styles.userInfoContainer_buttonsContainer_webSite}
            onMouseEnter={() => handleTooltip("web")}
            onMouseLeave={() => handleTooltip("web")}>
          <a href={formattedUrl} target="_blank" rel="noreferrer" className={styles.userInfoContainer_buttonsContainer_webSiteButton}>
            <WebIcon width={20} height={20}/>
          </a>
          {showToolTip.web.show && (
            <Tooltip text={showToolTip.web.text} fromHeaderNav />
          )}
        </div>  
            
        <div className={styles.userInfoContainer_buttonsContainer_contact} onClick={() => openSendContactNotificationModal( myPerson ? userData : profileVisualizerData)}>
          <p>Enviar mensaje</p>
          <ContactMessageIcon width={20} height={20}/> 
        </div>
        </>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.general.userData,
    reducedNav: state.general.reducedNav,
    userInfoNavStyle: state.general.userInfoNavStyle,
  };
};

export default connect(mapStateToProps, { setFile, openMainModal, closeMainModal, testPersonaEdit,
  testEmpresaEdit, setPreviousLocation, sendContactNotification, openSendContactNotificationModal})(UserInfo);
