import axios from "../../axios";
import * as actionTypes from "../actions/types";

export const getCategoriesWithMaterials = () => async (dispatch) => {
    let authToken = localStorage.getItem("access-Token-Edify");
    let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

    try {
        const res = await axios.get('/category/all/materials', bearerToken);
        dispatch({
            type: actionTypes.GET_CATEGORIES_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: actionTypes.GET_CATEGORIES_ERROR,
            payload: err.message,
        });
    }
};

export const sendQuote = (quoteData, materials) => (dispatch) => {
    let authToken = localStorage.getItem("access-Token-Edify");
    let bearerToken = { headers: { Authorization: `Bearer ${authToken}` } };

    dispatch({ type: actionTypes.SEND_QUOTE_REQUEST });

    const queryParams = {
        active: 1,
        description: quoteData.description,
        name: quoteData.name,
        zone: quoteData.city,
        startDate: quoteData.receiveFrom,
        endDate: quoteData.receiveTo,
        type: "PRIVATE",
        jurisdiccion: quoteData.jurisdiccion,
        currency: quoteData.currency
    };

    let sendUrl = `/quotation/assign?description=${encodeURIComponent(queryParams.description)}&endDate=${queryParams.endDate}&active=${queryParams.active}&name=${encodeURIComponent(queryParams.name)}&startDate=${queryParams.startDate}&zone=${encodeURIComponent(queryParams.zone)}&currency=${encodeURIComponent(queryParams.currency)}&jurisdiccion=${encodeURIComponent(queryParams.jurisdiccion)}&type=${encodeURIComponent(queryParams.type)}`;

    axios.post(sendUrl, null, bearerToken)
        .then((quotationResponse) => {
            const quotationId = quotationResponse.data.id;
            if (!Array.isArray(materials)) {
                throw new Error('materials no es un array');
            }

            const subQuotationMaterials = [];

            materials.forEach(material => {
                if (subQuotationMaterials.length) {
                    const index = subQuotationMaterials.findIndex(item => item.category_id === material.category_id)
                    if ( index !== -1) {
                        subQuotationMaterials[index].materials.push({
                            temporary: material.temporary,
                            amount: material.amount,
                            material_id: material.id,
                            name: material.name,
                            unit: material.unit
                        })
                    } else {
                        subQuotationMaterials.push({
                            name: material.category,
                            category_id: material.category_id,
                            materials: [{
                                temporary: material.temporary,
                                amount: material.amount,
                                material_id: material.id,
                                name: material.name,
                                unit: material.unit
                            }]
                        })
                    }
                } else {
                    subQuotationMaterials.push({
                        name: material.category,
                        category_id: material.category_id,
                        materials: [{
                            temporary: material.temporary,
                            amount: material.amount,
                            material_id: material.id,
                            name: material.name,
                            unit: material.unit
                        }]
                    })
                }
            });

            return axios.post('/sub_quotation/materials/crear', {
                quotation_id: quotationId,
                subQuotationMaterials
            }, bearerToken);
        })
        .then((subqui) => {
            dispatch({ type: actionTypes.SEND_QUOTE_SUCCESS });
        })
        .catch((error) => {
            dispatch({
                type: actionTypes.SEND_QUOTE_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
            console.error('Error en sendQuote:', error);
        });
};

export const resetSuccess = () => ({
    type: actionTypes.RESET_SUCCESS,
});

