import React from "react";
import ProjectsItem from "../project/ProjectsItem";

const AdjudicatedItem = ({ item }) => {
  const nombre = item?.name?.replace(/ /g, "-");

  const handleOnClick = () => {
    window.open(
      `/obraspublicas/proyectos-adjudicados/${item.id}/${nombre}`,
      "_blank"
    );
  };

  return (
    <ProjectsItem handleOnClick={handleOnClick} item={item} isAdjudicated />
  );
};

export default AdjudicatedItem;
